// urlB64ToUint8Array is a magic function that will encode the base64 public key

import register from "./register";

// to Array buffer which is needed by the subscription option
const urlB64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const subscribe = async () => {
  if (!("serviceWorker" in navigator)) return;

  const registration = await navigator.serviceWorker.ready;
  const options = {
    applicationServerKey: urlB64ToUint8Array(
      "BNHmKAdq3zjjn6s_oNQwJALCfnKt54szkk6iIIPii7_y0a3sb_cMaUoY7exukrUAGVubT-58uTun81PkRuuJckA",
    ),
    userVisibleOnly: true,
  };
  await registration.pushManager.subscribe(options);

  register(registration);
};

export default subscribe;
