const register = async (registration) => {
  try {
    const subscription = await registration.pushManager.getSubscription();
    // Post the notification credentials to server
    const res = await fetch("/notification/identity/send", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(subscription),
    });

    if (res.ok) {
      localStorage.setItem("notification-permission", "granted");
    } else {
      throw Error("Push Notification credentials to server attempt failed!");
    }
  } catch (e) {
    console.error("Error", e);
  }
};

export default register;
